<template>
  <!-- eslint-disable max-len -->
  <div class="sign-in">
    <div class="sign-in-wrapper">
      <div class="sign-in-container">
        <div class="sign-in-info">
          <div class="sign-in-info-image"></div>
          <div class="sign-in-info-container">
            <div class="sign-in-info-title">Well, hello</div>
            <div class="sign-in-info-description">
              This console will help you to administrate subscribers, products and payments for your
              club. Only appropriate staff should access this area as sensitive information may be
              viewable. Please consult your systems administrator if you are unsure of whether you
              have access to this console.
            </div>
          </div>
        </div>
        <div v-loading="isLoading" class="sign-in-form">
          <div class="sign-in-form-title">Sign In</div>
          <div class="sign-in-form-input">
            <BaseInput ref="email" v-model="form.email" placeholder="Email" />
          </div>
          <div class="sign-in-form-input">
            <BaseInput
              v-if="!isForgotPassword"
              ref="password"
              v-model="form.password"
              placeholder="Password"
              type="password"
              @keyup.enter="handleSubmitForm"
            />
          </div>
          <div v-if="!isForgotPassword" class="sign-in-form-button">
            <BaseButton @click="handleSubmitForm"> Sign In </BaseButton>
            <div v-if="error !== ''" class="sign-in-error">
              {{ error }}
            </div>
          </div>
          <div v-else class="sign-in-form-button">
            <BaseButton @click="handleResetPassword"> Reset password </BaseButton>
            <div v-if="error !== ''" class="sign-in-error">
              {{ error }}
            </div>
          </div>
          <div class="sign-in-forgot">
            <div
              v-if="!isForgotPassword"
              class="sign-in-forgot-button"
              @click="handleForgotPassword"
            >
              Forgot password?
            </div>
            <div v-else class="sign-in-forgot-button" @click="handleBackToSignIn">
              Back to sign in
            </div>
          </div>
        </div>
      </div>

      <div class="klubba-logo">
        <inline-svg :src="require('@/assets/icons/logo.svg')" />
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "@/config/firebase";
import BaseInput from "@/components/BaseInput";
import BaseButton from "@/components/BaseButton";
import InlineSvg from "vue-inline-svg";

export default {
  name: "SignIn",
  components: {
    BaseInput,
    BaseButton,
    InlineSvg,
  },
  data() {
    return {
      isForgotPassword: false,
      isLoading: false,
      error: "",
      form: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    isLoggedIn() {
      return firebase.auth().currentUser !== null;
    },
  },
  watch: {
    isLoggedIn: {
      handler() {
        if (this.isLoggedIn) {
          this.$router.push("/");
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.email.$el.children[0].focus();
    });
    firebase.auth().onAuthStateChanged((user) => {
      if (user !== null) {
        this.$router.push("/");
      }
    });
  },
  methods: {
    handleForgotPassword() {
      this.isForgotPassword = true;
      this.error = "";
    },
    handleBackToSignIn() {
      this.isForgotPassword = false;
      this.error = "";
    },
    handleResetPassword() {
      this.isLoading = true;
      if (this.form.email.length > 0) {
        firebase
          .auth()
          .sendPasswordResetEmail(this.form.email)
          .then(() => {
            this.isLoading = false;
            this.isForgotPassword = false;
            this.error = "";
            this.$nextTick(() => {
              this.$refs.password.$el.children[0].focus();
            });
          })
          .catch((err) => {
            this.error = err.message;
            this.isLoading = false;
          });
      }
    },
    handleSubmitForm() {
      this.isLoading = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(() => {
          this.$router.push("/");
          this.isLoading = false;
        })
        .catch((err) => {
          this.error = err.message;
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.sign-in {
  background: #00001b;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  overflow: auto;
}

.klubba-logo {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0;

  svg {
    height: 35px;
    width: auto;
  }
}

.sign-in-wrapper {
  position: relative;
  padding-top: 70px;
  margin: auto;
}

.sign-in-container {
  min-height: 520px;
  background: #fff;
  max-width: 1110px;
  overflow: hidden;
  border-radius: 6px;
  display: flex;
}

.sign-in-info {
  flex: 0 0 445px;
  background: #0ba3a9;
}

.sign-in-info-image {
  height: 174px;
  background: url("~@/assets/sign-in.jpg") no-repeat center / cover;
}

.sign-in-info-container {
  padding: 36px 24px;
  position: relative;
}

.sign-in-info-title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.5px;
  color: #ffffff;
  margin-bottom: 32px;
}

.sign-in-info-description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #ffffff;
  opacity: 0.9;
}

.sign-in-form {
  flex: 1;
  padding: 56px 32px 56px;
}

.sign-in-form-title {
  margin-bottom: 48px;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 62px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #0ba3a9;
}

.sign-in-form-input {
  margin-bottom: 24px;
}

.sign-in-form-button {
  margin-top: 40px;
  position: relative;
}

.sign-in-form-button :deep(.base-button) {
  max-width: 100%;
}

.sign-in-forgot {
  margin-top: 32px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.sign-in-forgot-button {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #a0a4b0;
  cursor: pointer;
}

.sign-in-error {
  position: absolute;
  top: calc(-100% + 24px);
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #f7685b;
}
</style>
